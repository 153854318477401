import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from 'gatsby-image'
import OSB from '../downloads/OSB-2022.pdf'
import Sundrella from '../downloads/MillDirect2022.pdf'
import { graphql } from 'gatsby'

const HeroSmall = styled.div`
  margin: 0 auto;
  width: 100%;
  display: block;

  @media (min-width: 768px) {
    display: none;
  }
`
const HeroLarge = styled.div`
  margin: 0 auto;
  width: 100%;
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`
const ContentWrapper = styled.div`
  margin: 0 auto;

  @media (min-width: 1024px) {
    width: 80%;
    max-width: 1200px;
  }
`
const AboutUs = styled.div`
  margin: 2em auto 2em;
  padding: 0 1.5em;
  text-align: center;

  @media (min-width: 768px) {
    margin: 2.8em auto 3.2em;
    padding: 0 2.5em;
  }

  @media (min-width: 1024px) {
    margin: 4.2em auto;
  }
`
const AboutUsH2 = styled.h2`
  font-family: Cormorant Garamond, serif;
  font-weight: 400;
  font-size: 1.9rem;
  margin: 0 auto .4em;
  display: flex;

  &:before, :after {
    color: white;
    content: '';
    flex: 1;
    border-bottom: groove 1px;
    margin: auto 0.5em;
    box-shadow: 0 -1px;
  }

  @media (min-width: 768px) {
    width: 75%;
    font-size: 2.3rem;
    margin: 0 auto .6em;
  }

  @media (min-width: 1335px) {
    width: 65%;
    font-size: 2.5rem;
    margin: 0 auto .6em;
  }
`
const AboutUsP = styled.p`
  font-family: Lato, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.7;

  @media (min-width: 768px) {
    font-size: 1.1rem;
    line-height: 1.8;
  }

  @media (min-width: 1024px) {
    font-size: 1.2rem;
    line-height: 1.9;
  }
`

const Section = styled.div`
  margin: 0 auto;

  @media (min-width: 768px) {
    display: flex;
  }
`
const SectionH2 = styled.h2`
  font-family: Cormorant Garamond, serif;
  font-weight: 400;
  font-size: 1.9rem;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    font-size: 2.3rem;
    margin-bottom: 20px;
  }

  @media (min-width: 1335px) {
    font-size: 2.5rem;
  }
`

const SectionTextBlock = styled.div`
  margin: 0 auto 1em;
  padding: 1.5em 1.5em;
  text-align: center;
  align-self: center;

  @media (min-width: 768px) {
    margin: 0 auto;
    width: 50%;
    padding: 3em 3em;
  }

  @media (min-width: 1335px) {
    padding: 3em 8em;
  }
`
const SectionImageBlock = styled.div`
  margin: 0 auto;
  text-align: center;

  @media (min-width: 768px) {
    width: 50%;
  }
`
const SectionImageBlockOdd = styled.div`
  margin: 0 auto;
  text-align: center;

  @media (min-width: 768px) {
    width: 50%;
    order: 1;
  }
`
const CatalogLink = styled.a`
  display: inline-block;
  font-family: Lato, sans-serif;
  font-weight: 400;
  font-size: .8rem;
  color: #222;
  border: 1px solid #222;
  padding: 1em 3em;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  @media (min-width: 768px) {
    font-size: .9rem;
  }
`

const IndexPage = (props) => (
  <Layout>
    <SEO title="Mill Direct" />
    <HeroSmall>
      <Img fluid={props.data.heroSmall.childImageSharp.fluid} />
    </HeroSmall>
    <HeroLarge>
      <Img fluid={props.data.heroLarge.childImageSharp.fluid} />
    </HeroLarge>
    <ContentWrapper>
      <AboutUs>
        <AboutUsH2>ABOUT US</AboutUsH2>
        <AboutUsP>As one of the largest privately held mill direct brands in America representing Terry towels, napery, outdoor furnishings and custom replacement cushions. Our company and sales team has worked with many of the largest brands in each of their respective niche markets; designing, creating, manufacturing, and distributing superior quality products, at a fair price and in a timely manner. With over three decades of relationship building in these markets with independent hoteliers, resorts, cruise lines, distributors, commercial laundries, assisted living facilities and multifamily and student communities as well as a strong focus in the architecture and design community it’s highly likely that you are or would be familiar with our clients. We offer a wide range of OS&E products and a very focused Made in America offering of FF&E and look forward to discussing opportunities with your team.</AboutUsP>
      </AboutUs>
      <Section>
        <SectionImageBlock>
          <Img fluid={props.data.imageTextiles.childImageSharp.fluid} />
        </SectionImageBlock>
        <SectionTextBlock>
          <SectionH2>
            TEXTILES
          </SectionH2>
          <CatalogLink href={OSB} target="blank">VIEW CATALOG</CatalogLink>
        </SectionTextBlock>
      </Section>
      <Section>
        <SectionImageBlockOdd>
          <Img fluid={props.data.imageOutdoorFurnishings.childImageSharp.fluid} />
        </SectionImageBlockOdd>
        <SectionTextBlock>
          <SectionH2>
            DOMESTIC OUTDOOR FURNISHINGS
          </SectionH2>
          <CatalogLink href={Sundrella} target="blank">VIEW CATALOG</CatalogLink>
        </SectionTextBlock>
      </Section>
      <Section>
        <SectionImageBlock style={{padding: `4em`}}>
          <Img fluid={props.data.imageReplacementCushions.childImageSharp.fluid} />
        </SectionImageBlock>
        <SectionTextBlock>
          <SectionH2>
            OUTDOOR REPLACEMENT CUSHIONS
          </SectionH2>
        </SectionTextBlock>
      </Section>
    </ContentWrapper>
  </Layout>
)

export default IndexPage

export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 1800) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export const pageQuery = graphql`
  query {
    heroLarge: file(relativePath: { eq: "sundrella-woman-lg.jpg" }) {
      ...fluidImage
    }
    heroSmall: file(relativePath: { eq: "sundrella-woman-sm.jpg" }) {
      ...fluidImage
    }
    imageTextiles: file(relativePath: { eq: "textiles.jpg" }) {
      ...fluidImage
    }
    imageOutdoorFurnishings: file(relativePath: { eq: "outdoor-furnishings.jpg" }) {
      ...fluidImage
    }
    imageReplacementCushions: file(relativePath: { eq: "replacement-cushions.jpg" }) {
      ...fluidImage
    }
  }
`